import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import { BiChevronRight } from 'react-icons/bi';
import { answerToChartLabel } from '../../../patient-app-common/helpers/progress_helper';
import Modal from '../../layout/Modal';
import useModal from '../../../hooks/useModal';
import LinePreview from './line/LinePreview';
import LineChartModal from './line/LineChartModal';
import EntryList from './EntryList';
import WeeklyTableData from './table/WeeklyTableData';
import TablePreview from './table/TablePreview';
import ImgList from './ImgList';

export default function ChartsList({ chartQuestions }) {
  const { t } = useTranslation('progress');

  const getSummary = (question) => {
    switch (question.type) {
      case 'camera':
        return <ImgSummary question={question} />;
      case 'checkbox':
      case 'radio':
        return <TableSummary question={question} />;

      case 'gen_scale':
      case 'numerical_input':
      case 'picker':
        return <LineChartSummary question={question} />;

      case 'text':
        return <TextSummary question={question} />;

      default:
        return null;
    }
  };

  if (!chartQuestions.length) {
    return (
      <div className="flex justify-center">
        <div className="text-center w-full">
          <div className="flex justify-center py-8">
            <img src="/assets/images/file-search.png" alt="" />
          </div>
          <h2 className="font-light text-gray-600 text-lg sm:text-xl">
            {t('chartslist.empty_text')}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto w-full md:w-1/2">
      <div className="flex flex-col my-8">
        {chartQuestions.map((question) => {
          if (!question.patientEntries.length) return null;
          return (
            <div key={`${question.procedureId}-${question.id}`}>
              {getSummary(question)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function TableSummary({ question }) {
  const { t } = useTranslation('progress');
  const { visible, toggleModal } = useModal();
  const lastEntry = _.last(question.patientEntries);
  const { date } = lastEntry;

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<WeeklyTableData question={question} />}
      />
      <Card key={question.id}>
        <Title>{question.chartTitle}</Title>
        <div className="mx-auto my-4">
          <TablePreview question={question} />
        </div>
        <p className="text-sm">{t('chartslist.date', { fromNow: moment(date).fromNow() })}</p>
        <button className="text-left mt-4" onClick={toggleModal}>
          <Highlight>
            {t('common.link.all_entries')}
            <BiChevronRight className="inline-flex" />
          </Highlight>
        </button>
      </Card>
    </>
  );
}

function LineChartSummary({ question }) {
  const { t } = useTranslation('progress');
  const { visible, toggleModal } = useModal();
  const lastEntry = _.last(question.patientEntries);
  const { answer, date } = lastEntry;
  const { unit } = question;
  const parsedAnswer = answerToChartLabel(question, answer);

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<LineChartModal question={question} />}
      />
      <Card key={question.id}>
        <Title>{question.chartTitle}</Title>
        <div className="grid grid-cols-2">
          <div>
            <Subtitle>{parsedAnswer} {unit}</Subtitle>
            <p className="text-sm">{t('chartslist.date', { fromNow: moment(date).fromNow() })}</p>
            <button className="text-left mt-4" onClick={toggleModal}>
              <Highlight>
                {t('common.link.all_entries')}
                <BiChevronRight className="inline-flex" />
              </Highlight>
            </button>
          </div>
          <div>
            <LinePreview question={question} />
          </div>
        </div>
      </Card>
    </>
  );
}

function TextSummary({ question }) {
  const { t } = useTranslation('progress');
  const { visible, toggleModal } = useModal();
  const entriesWithText = _.filter(question.patientEntries, 'answer');
  if (!entriesWithText.length) return null;
  const { answer, date } = _.last(entriesWithText);

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<EntryList question={question} />}
      />
      <Card key={question.id}>
        <Title>{question.chartTitle}</Title>
        <p className="font-light my-4 whitespace-pre-wrap break-words">
          {answer}
        </p>
        <p className="text-sm">{t('chartslist.date', { fromNow: moment(date).fromNow() })}</p>
        <button className="text-left mt-4" onClick={toggleModal}>
          <Highlight>
            {t('common.link.all_entries')}
            <BiChevronRight className="inline-flex" />
          </Highlight>
        </button>
      </Card>
    </>
  );
}

function ImgSummary({ question }) {
  const { t } = useTranslation('progress');
  const { visible, toggleModal } = useModal();
  const entriesWithImg = _.filter(question.patientEntries, ({ answer }) => !_.isEmpty(answer));
  if (!entriesWithImg.length) return null;
  const { answer, date } = _.last(entriesWithImg);

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<ImgList question={question} />}
      />
      <Card key={question.id}>
        <Title>{question.chartTitle}</Title>
        <figure className="grid grid-cols-2">
          <div className="flex flex-col justify-end">
            <figcaption className="text-sm">
              {t('chartslist.date', { fromNow: moment(date).fromNow() })}
            </figcaption>
            <button className="text-left mt-4" onClick={toggleModal}>
              <Highlight>
                {t('common.link.all_entries')}
                <BiChevronRight className="inline-flex" />
              </Highlight>
            </button>
          </div>
          <div className="flex justify-end">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={answer} style={{ height: '100px' }} />
          </div>
        </figure>
      </Card>
    </>
  );
}

const Card = styled.div(() => [
  tw`bg-white border-gray-500 rounded-lg shadow-md m-4 p-8`,
]);

const Title = styled.p(() => [
  tw`font-semibold text-lg`,
]);

const Subtitle = styled.p(() => [
  tw`font-light text-lg py-4`,
]);

const Highlight = styled.p(() => [
  tw`font-light text-sm text-blue-100`,
]);
