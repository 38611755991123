import { createSlice } from '@reduxjs/toolkit';

import { patientApi } from '../api/patientApi';

import type { PayloadAction } from '@reduxjs/toolkit';

type AppState = {
  dischargePrompt: boolean,
  hospitalLogo: string | null,
  appVariant: string,
  procedureLogo: string | null,
  route: string | null,
  secureMessagingEnabled: boolean;
  testEnv: boolean,
  unitPref: string | null,
};

const initialState = {
  dischargePrompt: true,
  appVariant: 'seamless',
  route: null,
  testEnv: false,
  secureMessagingEnabled: false,
  unitPref: null,
} as AppState;

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDischargePrompt: (
      state,
      { payload: dischargePrompt }: PayloadAction<boolean>,
    ) => {
      state.dischargePrompt = dischargePrompt;
    },
    setAppVariant: (
      state,
      { payload: appVariant }: PayloadAction<string>,
    ) => {
      state.appVariant = appVariant;
    },
    setRoute: (
      state,
      { payload: route }: PayloadAction<string>,
    ) => {
      state.route = route;
    },
    setTestEnv: (
      state,
      { payload: testEnv }: PayloadAction<boolean>,
    ) => {
      state.testEnv = testEnv;
    },
    setUnitPref: (
      state,
      { payload: unitPref }: PayloadAction<string>,
    ) => {
      state.unitPref = unitPref;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        patientApi.endpoints.getPatient.matchFulfilled,
        (state, { payload }) => {
          if (payload.server === 'US') {
            state.unitPref = 'imperial';
          }
          if (payload.server === 'CA') {
            state.unitPref = 'metric';
          }
        },
      )
      .addMatcher(
        patientApi.endpoints.getPatient.matchFulfilled,
        (state, { payload }) => {
          state.procedureLogo = payload.procedureLogo;
          state.secureMessagingEnabled = payload.secureMessagingEnabled;
        },
      )
  },
});

export const {
  setDischargePrompt,
  setAppVariant,
  setRoute,
  setTestEnv,
  setUnitPref,
} = slice.actions;

export default slice.reducer;
