// The authSlice follows the example in RTK documentation
// https://redux-toolkit.js.org/rtk-query/usage/examples#using-extrareducers

import { createAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from '../api/authApi';
import { patientApi } from '../api/patientApi';

import type { PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  admin: boolean,
  demo: boolean,
  mfaContact: string,
  mfaChecked: boolean,
  mfaMethod: 'call' | 'sms' | null,
  patientId: string | null,
  providerId: string | null,
  providerMode: boolean,
  setupUrl: string | null;
  token: string | null,
};

const initialState = {
  admin: false,
  demo: false,
  mfaContact: "",
  mfaChecked: true,
  mfaMethod: null,
  patientId: null,
  providerId: null,
  providerMode: false,
  setupUrl: null,
  token: null,
} as AuthState;

export const logout = createAction('auth/logout');

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMfaChecked: (
      state,
      { payload: value }: PayloadAction<boolean>,
    ) => {
      state.mfaChecked = value;
    },
    setProviderMode: (
      state,
      { payload: providerMode }: PayloadAction<boolean>,
    ) => {
      state.providerMode = providerMode;
    },
    setToken: (
      state,
      { payload: token }: PayloadAction<string>,
    ) => {
      state.token = token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.token = null;
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
          state.mfaChecked = payload.mfaChecked;
          state.mfaContact = payload.mfaContact;
          state.mfaMethod = payload.mfaMethod;
        },
      )
      .addMatcher(
        authApi.endpoints.autoAuth.matchFulfilled,
        (state, { payload }) => {
          state.mfaChecked = payload.mfaChecked;
          state.mfaContact = payload.mfaContact;
          state.mfaMethod = payload.mfaMethod;
          state.admin = payload.admin;
          state.providerId = payload.providerId;
          state.setupUrl = payload.setupUrl;
        },
      )
      .addMatcher(
        authApi.endpoints.setupTwoFA.matchFulfilled,
        (state, { payload }) => {
          if (payload.success) {
            state.mfaChecked = true;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.checkTwoFACode.matchFulfilled,
        (state, { payload }) => {
          if (payload.success) {
            state.mfaChecked = true;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.setup.matchFulfilled,
        (state, { payload }) => {
          state.mfaChecked = payload.mfaChecked;
          state.mfaContact = payload.mfaContact;
          state.token = payload.token;
        },
      )
      .addMatcher(
        authApi.endpoints.signup.matchFulfilled,
        (state, { payload }) => {
          state.mfaChecked = payload.mfaChecked;
          state.mfaContact = payload.mfaContact;
          state.token = payload.token;
        },
      )
      .addMatcher(
        patientApi.endpoints.getPatient.matchFulfilled,
        (state, { payload }) => {
          state.patientId = `${payload.server}-${payload.uid}`;
          state.demo = payload.demo;
          state.mfaContact = payload.mfaContact;
        },
      );
  },
});

export const {
  setMfaChecked,
  setProviderMode,
  setToken
} = slice.actions;

export default slice.reducer;
