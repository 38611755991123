/* eslint-disable import/order */
/* eslint-disable import/extensions */
import common_en from './en/common.json';
import education_en from './en/education.json';
import home_en from './en/home.json';
import login_en from './en/login.json';
import progress_en from './en/progress.json';
import settings_en from './en/settings.json';
import survey_en from './en/survey.json';
import todo_en from './en/todo.json';
import inbox_en from './en/inbox.json';

import common_ar from './ar/common.json';
import education_ar from './ar/education.json';
import home_ar from './ar/home.json';
import login_ar from './ar/login.json'
import progress_ar from './ar/progress.json';
import settings_ar from './ar/settings.json';
import survey_ar from './ar/survey.json';
import todo_ar from './ar/todo.json';
import inbox_ar from './ar/inbox.json';

import common_es from './es/common.json';
import education_es from './es/education.json';
import home_es from './es/home.json';
import login_es from './es/login.json';
import progress_es from './es/progress.json';
import settings_es from './es/settings.json';
import survey_es from './es/survey.json';
import todo_es from './es/todo.json';
import inbox_es from './es/inbox.json';

import common_fr from './fr/common.json';
import education_fr from './fr/education.json';
import home_fr from './fr/home.json';
import login_fr from './fr/login.json'
import progress_fr from './fr/progress.json';
import settings_fr from './fr/settings.json';
import survey_fr from './fr/survey.json';
import todo_fr from './fr/todo.json';
import inbox_fr from './fr/inbox.json';

import common_it from './it/common.json';
import education_it from './it/education.json';
import home_it from './it/home.json';
import login_it from './it/login.json'
import progress_it from './it/progress.json';
import settings_it from './it/settings.json';
import survey_it from './it/survey.json';
import todo_it from './it/todo.json';
import inbox_it from './it/inbox.json';

import common_hi from './hi/common.json';
import education_hi from './hi/education.json';
import home_hi from './hi/home.json';
import login_hi from './hi/login.json'
import progress_hi from './hi/progress.json';
import settings_hi from './hi/settings.json';
import survey_hi from './hi/survey.json';
import todo_hi from './hi/todo.json';
import inbox_hi from './hi/inbox.json';

import common_pa from './pa/common.json';
import education_pa from './pa/education.json';
import home_pa from './pa/home.json';
import login_pa from './pa/login.json'
import progress_pa from './pa/progress.json';
import settings_pa from './pa/settings.json';
import survey_pa from './pa/survey.json';
import todo_pa from './pa/todo.json';
import inbox_pa from './pa/inbox.json';

import common_pl from './pl/common.json';
import education_pl from './pl/education.json';
import home_pl from './pl/home.json';
import login_pl from './pl/login.json'
import progress_pl from './pl/progress.json';
import settings_pl from './pl/settings.json';
import survey_pl from './pl/survey.json';
import todo_pl from './pl/todo.json';
import inbox_pl from './pl/inbox.json';

import common_pt from './pt/common.json';
import education_pt from './pt/education.json';
import home_pt from './pt/home.json';
import login_pt from './pt/login.json'
import progress_pt from './pt/progress.json';
import settings_pt from './pt/settings.json';
import survey_pt from './pt/survey.json';
import todo_pt from './pt/todo.json';
import inbox_pt from './pt/inbox.json';

import common_ur from './ur/common.json';
import education_ur from './ur/education.json';
import home_ur from './ur/home.json';
import login_ur from './ur/login.json'
import progress_ur from './ur/progress.json';
import settings_ur from './ur/settings.json';
import survey_ur from './ur/survey.json';
import todo_ur from './ur/todo.json';
import inbox_ur from './ur/inbox.json';

import common_zh_HK from './zh-HK/common.json';
import education_zh_HK from './zh-HK/education.json';
import home_zh_HK from './zh-HK/home.json';
import login_zh_HK from './zh-HK/login.json'
import progress_zh_HK from './zh-HK/progress.json';
import settings_zh_HK from './zh-HK/settings.json';
import survey_zh_HK from './zh-HK/survey.json';
import todo_zh_HK from './zh-HK/todo.json';
import inbox_zh_HK from './zh-HK/inbox.json';

import common_zh_CN from './zh-CN/common.json';
import education_zh_CN from './zh-CN/education.json';
import home_zh_CN from './zh-CN/home.json';
import login_zh_CN from './zh-CN/login.json'
import progress_zh_CN from './zh-CN/progress.json';
import settings_zh_CN from './zh-CN/settings.json';
import survey_zh_CN from './zh-CN/survey.json';
import todo_zh_CN from './zh-CN/todo.json';
import inbox_zh_CN from './zh-CN/inbox.json';


export const resources = {
  ar: {
    common: common_ar,
    education: education_ar,
    home: home_ar,
    login: login_ar,
    progress: progress_ar,
    settings: settings_ar,
    survey: survey_ar,
    todo: todo_ar,
    inbox: inbox_ar,
  },
  en: {
    common: common_en,
    education: education_en,
    home: home_en,
    login: login_en,
    progress: progress_en,
    settings: settings_en,
    survey: survey_en,
    todo: todo_en,
    inbox: inbox_en,
  },
  es: {
    common: common_es,
    education: education_es,
    home: home_es,
    login: login_es,
    progress: progress_es,
    settings: settings_es,
    survey: survey_es,
    todo: todo_es,
    inbox: inbox_es,
  },
  fr: {
    common: common_fr,
    education: education_fr,
    home: home_fr,
    login: login_fr,
    progress: progress_fr,
    settings: settings_fr,
    survey: survey_fr,
    todo: todo_fr,
    inbox: inbox_fr,
  },
  it: {
    common: common_it,
    education: education_it,
    home: home_it,
    login: login_it,
    progress: progress_it,
    settings: settings_it,
    survey: survey_it,
    todo: todo_it,
    inbox: inbox_it,
  },
  hi: {
    common: common_hi,
    education: education_hi,
    home: home_hi,
    login: login_hi,
    progress: progress_hi,
    settings: settings_hi,
    survey: survey_hi,
    todo: todo_hi,
    inbox: inbox_hi,
  },
  pa: {
    common: common_pa,
    education: education_pa,
    home: home_pa,
    login: login_pa,
    progress: progress_pa,
    settings: settings_pa,
    survey: survey_pa,
    todo: todo_pa,
    inbox: inbox_pa,
  },
  pl: {
    common: common_pl,
    education: education_pl,
    home: home_pl,
    login: login_pl,
    progress: progress_pl,
    settings: settings_pl,
    survey: survey_pl,
    todo: todo_pl,
    inbox: inbox_pl,
  },
  pt: {
    common: common_pt,
    education: education_pt,
    home: home_pt,
    login: login_pt,
    progress: progress_pt,
    settings: settings_pt,
    survey: survey_pt,
    todo: todo_pt,
    inbox: inbox_pt,
  },
  ur: {
    common: common_ur,
    education: education_ur,
    home: home_ur,
    login: login_ur,
    progress: progress_ur,
    settings: settings_ur,
    survey: survey_ur,
    todo: todo_ur,
    inbox: inbox_ur,
  },
  'zh-HK': {
    common: common_zh_HK,
    education: education_zh_HK,
    home: home_zh_HK,
    login: login_zh_HK,
    progress: progress_zh_HK,
    settings: settings_zh_HK,
    survey: survey_zh_HK,
    todo: todo_zh_HK,
    inbox: inbox_zh_HK,
  },
  'zh-CN': {
    common: common_zh_CN,
    education: education_zh_CN,
    home: home_zh_CN,
    login: login_zh_CN,
    progress: progress_zh_CN,
    settings: settings_zh_CN,
    survey: survey_zh_CN,
    todo: todo_zh_CN,
    inbox: inbox_zh_CN,
  },
};
