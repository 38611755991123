import _ from 'lodash';

import { uuidv4 } from '../utils';

import { getOffsetStart } from './logic_helpers';

export function surveyHelperContext({ patientDates, surveyData, editMode }) {
  const { questions } = surveyData;

  // validates whether a question is optional or has an answer in the correct type
  // returns a boolean
  function validateAnswer(questionType, answer) {
    switch (questionType) {
      case 'blank':
      case 'instruction':
        return true;

      case 'camera':
        return _.isBoolean(answer) || _.isObject(answer) || _.isString(answer);

      case 'checkbox':
        return Array.isArray(answer) && answer.includes(true);

      case 'date':
        return _.isString(answer);

      case 'device':
        return _.isBoolean(answer) || _.isString(answer);

      case 'face_scale':
      case 'gen_scale':
      case 'picker':
      case 'radio':
        return _.isNumber(answer);

      case 'medicine':
        return Array.isArray(answer);

      case 'numerical_input':
        return !_.isNil(answer) && _.isNumber(_.toNumber(answer));

      default:
        return answer != null;
    }
  }

  function removeInvalidAnswers({ currentAnswers, start, end }) {
    // create an array of skipped questions
    const skippedIDs = _.range(start + 1, end).map((qIndex) => `q${qIndex + 1}`);
    // answers of skipped questions are omitted
    const validAnswers = _.omit(currentAnswers, skippedIDs);
    return validAnswers;
  }

  // returns the question's reference event as a date string
  function getRefDateTime(event) {
    let dateTime = null;

    switch (event) {
      case 'signup':
        dateTime = patientDates.signupDate;
        break;
      case 'surgery':
        dateTime = patientDates.surgeryDate;
        break;
      case 'pre_op_visit':
        dateTime = patientDates.preOpDate;
        break;
      case 'discharge':
        dateTime = patientDates.dischargeDate;
        break;
      default:
        console.log('SurveyHelper getRefDateTime: No reference date found');
        break;
    }
    return dateTime;
  }

  // calculates the start and end date-time of the question in milliseconds
  // checks whether the current date-time is within the start and end date-time range
  // returns a boolean
  function meetsDateDependencies(refDate, dateDependencies) {
    const today = new Date().getTime();
    const { offsetType, startDate, endDate } = dateDependencies;

    const startDateTime = getOffsetStart(new Date(refDate).getTime(), startDate, offsetType);
    const endDateTime = getOffsetStart(new Date(refDate).getTime(), endDate, offsetType);

    if (today) {
      if (today >= startDateTime && today <= endDateTime) {
        return true;
      }
    }
    return false;
  }

  function meetsNumericalDependency({ answer, dependencyValue, comparator }) {
    let result = false;

    switch (comparator) {
      case 'greater_than':
        if (answer > dependencyValue) {
          result = true;
        }
        break;

      case 'greater_than_or_equal':
        if (answer >= dependencyValue) {
          result = true;
        }
        break;

      case 'less_than_or_equal':
        if (answer <= dependencyValue) {
          result = true;
        }
        break;

      case 'less_than':
        if (answer < dependencyValue) {
          result = true;
        }
        break;

      case 'exactly_equal':
        if (answer === dependencyValue) {
          result = true;
        }
        break;

      default:
        result = false;
        break;
    }

    return result;
  }

  // checks whether the question's answers matches to the answer piece's dependency
  // returns a boolean
  function meetsQuestionDependency({ answers, dependencies, type }) {
    let count = 0;

    dependencies.forEach((dependency) => {
      const answerIndex = dependency.answerArrayIndex;
      const questionID = dependency.question;

      if (!_.has(answers, `q${questionID}`)) return;

      const answer = answers[`q${questionID}`];
      const questionType = questions[(questionID - 1)].type;
      const logicValue = dependency.logicValue ?? 'true';

      switch (questionType) {
        case 'boolean':
        case 'face_scale':
        case 'gen_scale':
        case 'picker':
        case 'radio':
        case 'scale':
          if (answer === answerIndex
            && logicValue === 'true') {
            count++;
          }

          if (answer !== undefined
            && answer !== answerIndex
            && logicValue === 'false') {
            count++;
          }

          break;

        case 'checkbox':
          if (!answer) {
            return;
          }

          if (logicValue === 'true'
            && answer[answerIndex] === true) {
            count++;
          }

          if (logicValue === 'false'
            && answer[answerIndex] === false) {
            count++;
          }

          break;

        case 'numerical_input': {
          if (isNaN(answer)) {
            return;
          }

          const meetsDependency = meetsNumericalDependency({
            answer: _.round(answer, 2),
            dependencyValue: _.round(logicValue, 2),
            comparator: dependency.comparator,
          });

          if (meetsDependency) {
            count++;
          }
          break;
        }

        default:
          break;
      }
    });

    if ((!type || type === 'or') && count > 0) {
      return true;
    }

    if (type === 'and' && count === dependencies.length) {
      return true;
    }

    return false;
  }

  // checks whether the question's profile dependencies match the patient's profile characteristics
  // returns a boolean
  function meetsProfileDependency({ profileDependencies, type, profile }) {
    let count = 0;

    profileDependencies.forEach((dependency) => {
      try {
        const patientProfileValue = profile[dependency.keyId].value;

        if (dependency.value === patientProfileValue) {
          count++;
        }
      } catch (e) {
        console.error(e);
      }
    });

    if ((!type || type === 'or') && count > 0) {
      return true;
    }
    if (type === 'and' && count === profileDependencies.length) {
      return true;
    }
    return false;
  }

  // checks whether a question meets profile, date, and question dependencies
  // finds the next question index where dependencies are met
  // returns a number
  function getNextQuestion({ currentIndex, answers, profile }) {
    let nextQuestionIndex = 0;

    for (let i = currentIndex + 1; i < questions.length; i++) {
      const question = questions[i];

      let profileDependencies = false;
      let meetsProfile = false;

      let dateDependencies = false;
      let meetsDate = false;

      let questionDependencies = false;
      let meetsQuestion = false;

      if (question.profileDependencies.length > 0) {
        profileDependencies = true;

        meetsProfile = meetsProfileDependency({
          profileDependencies: question.profileDependencies,
          type: question.profileDependencyType,
          profile,
        });
      }

      if (question.dateDependencies.startDate != null) {
        dateDependencies = true;
        const referenceDate = getRefDateTime(question.dateDependencies.offsetEvent);

        if (referenceDate) {
          meetsDate = meetsDateDependencies(referenceDate, question.dateDependencies);
        }
      }

      if (question.questionDependencies.length > 0) {
        questionDependencies = true;
        meetsQuestion = meetsQuestionDependency({
          answers,
          dependencies: question.questionDependencies,
          type: question.questionDependencyType,
        });
      }

      if (!questionDependencies && !profileDependencies && !dateDependencies) {
        nextQuestionIndex = i;
        break;
      }

      const meetsDependency = meetsProfile || meetsDate || meetsQuestion;

      if (question.dependencyType === 'or' && meetsDependency) {
        nextQuestionIndex = i;
        break;
      }

      const meetsDependencies = (meetsProfile || !profileDependencies)
        && (meetsDate || !dateDependencies)
        && (meetsQuestion || !questionDependencies);

      if (question.dependencyType === 'and' && meetsDependencies) {
        nextQuestionIndex = i;
        break;
      }
    }

    return nextQuestionIndex;
  }

  function setProfile({ currentIndex, lastProfile, answers }) {
    const question = questions[currentIndex];
    const profileClone = _.cloneDeep(lastProfile);

    const answer = answers[question.id];
    const { profileRules } = surveyData;

    profileRules.forEach((rule) => {
      const ruleQuestionID = `q${rule.questionNumber}`;
      const profileKey = rule.keyId;
      const ruleAnswer = rule.answerArrayIndex;

      if (question.id === ruleQuestionID) {
        switch (question.type) {
          case 'radio':
          case 'boolean':
            if (ruleAnswer === answer) {
              profileClone[profileKey].value = rule.optionValue;
              profileClone[profileKey].wasUpdated = true;
            }
            break;

          case 'checkbox':
            if (answer && rule.value === answer[ruleAnswer].toString()) {
              profileClone[profileKey].value = rule.optionValue;
              profileClone[profileKey].wasUpdated = true;
            }
            break;

          default:
            break;
        }
      }
    });
    return profileClone;
  }

  function validateQuestions({
    currentIndex,
    currentAnswers,
    currentHistory,
    currentProfiles,
  }) {
    // create answers object to update React state
    let validAnswers = currentAnswers;

    // create profile for current question to generate profiles history
    const profile = setProfile({
      currentIndex,
      lastProfile: _.last(currentProfiles),
      answers: validAnswers,
    });

    const validProfiles = [...currentProfiles, profile];

    // get next valid question to generate questions history
    const validQuestions = currentHistory;

    const nextIndex = getNextQuestion({
      currentIndex,
      answers: currentAnswers,
      profile,
    });

    validQuestions.push(nextIndex);

    if (nextIndex === 0) {
      if (currentIndex !== questions.length - 1) {
        // omit answers of skipped questions after the last valid question in survey
        validAnswers = removeInvalidAnswers({
          currentAnswers,
          start: currentIndex,
          end: questions.length,
        });
      }
      return { validQuestions, validProfiles, validAnswers };
    }

    // omit answers of skipped questions
    validAnswers = removeInvalidAnswers({
      currentAnswers,
      start: currentIndex,
      end: nextIndex,
    });

    if (_.size(currentAnswers) !== _.size(validAnswers)) {
      // if any answers were omitted, rerun function to get next valid question
      return validateQuestions({
        currentIndex,
        currentAnswers: validAnswers,
        currentHistory: validQuestions,
        currentProfiles: validProfiles,
      });
    }

    // in edit mode, check if the question was already answered
    if (editMode) {
      const nextHasAnswer = validateAnswer(
        questions[nextIndex]?.type,
        validAnswers[questions[nextIndex]?.id],
      );

      if (nextHasAnswer) {
        // if question was answered, rerun function to get next valid question
        return validateQuestions({
          currentIndex: nextIndex,
          currentAnswers: validAnswers,
          currentHistory: validQuestions,
          currentProfiles: validProfiles,
        });
      }
    }

    return {
      validQuestions,
      validProfiles,
      validAnswers,
    };
  }

  return {
    getNextQuestion,
    meetsDateDependencies,
    meetsQuestionDependency,
    meetsProfileDependency,
    setProfile,
    validateAnswer,
    validateQuestions,
  };
}

export function reviewHelperContext({ surveyData, answers, profile }) {
  const { questions } = surveyData;

  // returns an object with updated profile values
  function createProfileParams() {
    const profileCopy = {};

    for (const key in profile) {
      if (profile[key].wasUpdated) {
        profileCopy[key] = profile[key].value;
      }
    }

    return profileCopy;
  }

  // checks if medicine questions have been answered and creates params for them
  // returns an object
  function createMedProfile(medicineHistory) {
    let history = {};
    if (medicineHistory) {
      history = JSON.parse(medicineHistory);
    }

    const medProfile = {};

    const medQuestions = questions.filter((question) => question.type === 'medicine');

    if (medQuestions.length === 0) {
      return null;
    }

    medQuestions.forEach((question) => {
      const answer = answers[question.id];

      if (!answer) {
        return null;
      }

      question.answers.forEach((medicine, i) => {
        const key = medicine.options.medicineId.toString();

        if (_.isEmpty(answer[i])) {
          history = _.omit(history, [key]);
        } else {
          medProfile[key] = answer[i];
        }
      });
    });

    return { ...history, ...medProfile };
  }

  // checks if camera questions have been answered and creates params for them
  // returns an object
  function createPhotoParams(mobilePlatform = null) {
    const params = {};
    const imageKeys = [];

    const cameraQuestionIDs = questions.filter((question) => question.type === 'camera')
      .map((question) => question.id);

    if (cameraQuestionIDs.length === 0) {
      return;
    }

    cameraQuestionIDs.forEach((questionID) => {
      const answer = answers[questionID];
      if (answer) {
        const imageKey = `image_${questionID}`;
        imageKeys.push(imageKey);

        if (mobilePlatform) {
          params[imageKey] = {
            name: uuidv4(),
            type: 'image/jpeg',
            uri: mobilePlatform === 'android' ? answers[questionID] : answers[questionID].replace('file://', ''),
          };
        } else {
          params[imageKey] = answer;
        }
      }
    });

    params.imageKeys = JSON.stringify(imageKeys);
    return params;
  }

  // creates a params object for survey submission
  function createSurveyParams(eventType, eventID) {
    // NOTE: milliseconds is truncated to seconds in database
    const timestamp = _.round(_.now(), -3);
    const answersCopy = { ...answers, created_at: timestamp / 1000 };

    const params = {
      createdAt: timestamp,
      data: JSON.stringify(answersCopy),
      procedureId: surveyData.id,
      applicationEventId: eventID,
      eventType,
    };

    return params;
  }

  return {
    createMedProfile,
    createProfileParams,
    createPhotoParams,
    createSurveyParams,
  };
}

export const getImageNumber = (string) => {
  const numbers = string.match(/\d+/g).map(Number);
  return numbers.toString();
};
