/* eslint-disable react/no-danger */
import React from "react";
import tw, { styled } from "twin.macro";

const TEMPLATES = [
  "atrium_consent",
  "baystate_consent_en",
  "baystate_consent_es",
  "montrealortho_consent_hip_en",
  "montrealortho_consent_hip_fr",
  "montrealortho_consent_knee_en",
  "montrealortho_consent_knee_fr",
  "mountsinai_consent",
  "nshealth_consent",
  "oakvalley_consent_en",
  "oakvalley_consent_zh-cn",
  "ohio_bariatrics_consent",
  "ohio_foregut_consent",
  "ohio_hernia_consent",
  "sjhh_consent"
];

export default function ConsentForm({ field }) {
  const html = `${process.env.PUBLIC_URL}/assets/html/${field.value}.html`;

  function iframe() {
    return {
      __html: `<iframe title="Consent form" src=${html} width="100%" height="600"></iframe>`,
    };
  }

  if (!TEMPLATES.includes(field.value)) {
    return null;
  }

  return (
    <>
      <HTMLContainer>
        <div dangerouslySetInnerHTML={iframe()} />
      </HTMLContainer>
    </>
  );
}

const HTMLContainer = styled.div(() => [
  tw`border border-gray-700 mb-4 rounded`,
  tw`overflow-scroll`,
]);
